<template>
  <b-card class="col-xl-6 pl-0 pr-0">
    <form @submit.prevent="validateBeforeSubmit">
      <h1>Add New Vendor Or WSS</h1>
      <p class="text-muted">Enter email address to invite to signup. Once user is <strong>signed up</strong> they will appear in your wss or vendor lists.</p>
      <b-input-group class="mt-3 mb-2">
        <b-input-group-prepend>
          <b-input-group-text>@</b-input-group-text>
        </b-input-group-prepend>
        <input type="text" class="form-control" v-model="email" v-validate="'required|email'" placeholder="Email" name="email" autocomplete="email" />
      </b-input-group>
      <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>

      <b-form-group class="mt-1">
        <b-form-radio-group  stacked id="radios2" v-model="status" name="radioSubComponent">
          <b-form-radio value="0">Vendor</b-form-radio>
          <b-form-radio value="1">WSS</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <i v-show="error" class="fa fa-exclamation-triangle"></i><span v-show="error" class="mb-3 help is-danger">Error. Email already exists. Please contact admin.</span>
      <b-button variant="success" class="mt-4" type="submit" block>Add Email</b-button>
    </form>
  </b-card>
</template>

<script>
export default {
  name: 'AddEmail',
  data: function () {
    return {
      email: '',
      error: false,
      status: 0,
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            projectid: 2,
            mail: this.email,
            status: this.status,
            isBulkEmail: 1
          })
          this.$http.post('/wts/create/email', data)
          .then(response => {
            this.$toasted.show('Email added successfully!', {type: 'success', duration: '3000'})
            this.email = '';
            this.$validator.reset();
          })
          .catch(error => {
            console.log(error)
            this.error = true;
          })
          return;
        }
      });
    }
  }
}
</script>

<style lang="scss">

</style>
